// eslint-disable-next-line import/prefer-default-export
export const autoSalesHero = {
  title: "Car dealer point-of-sale software",
  subtext:
    "Expand the reach of your auto sales business and grow revenue with simple tools to get discovered online, book more test drives & service appointments, and get paid faster.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-sales/demo",
  },
};
export const autoSalesSubHero = {
  title: "Grow your auto sales business with SpotOn",
  subtext:
    "With SpotOn, you’ll spend less time and money attracting new customers and more time selling vehicles, with easy-to-use tech to stand out from your competitors and increase revenue. ",
  list: [
    "Establish a strong online presence with a new website",
    "Improve your ratings on review websites",
    "Improve efficiency with calendaring and appointments",
    "Offer different ways to accept payments",
    "Engage customers with marketing campaigns",
  ],
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-sales/demo",
  },
};

export const autoSalesWhiteGlove = {
  mainTitle: "Service you can trust",
  title: "Local service & \n around-the-clock support",
  content:
    "You deserve the same level of service and value that you provide to your customers. Our expert team will set you up for success with a custom-fit solution to help you grow your auto sales business. Plus, you can count on our team whenever you need us, with local service and 24/7 technical support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-sales/demo",
  },
};

export const autoSalesLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "One integrated system",
      blockSubTitle:
        "Who has time to waste on confusing, mismatched software and payment solutions? Our cloud-based tools are thoughtfully designed to work together, with a single login, whether you’re on the lot selling cars, in the back office, or keeping tabs on your business from your mobile phone. And our pricing is fair, so you pay less while earning more.",
      blockList: [
        "Transparent pricing",
        "Next-day funding",
        "No hidden fees",
        "No long-term commitment",
      ],
      blockImg: "auto-sales-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-sales/demo",
      },
    },
  ],
};

export const autoSalesTrusted = [
  {
    title: "91%",
    subtext:
      "Of Millennials trust online reviews as much as friends and family",
  },
  {
    title: "88%",
    subtext: "Of people search online before visiting a business",
  },
  {
    title: "$42",
    subtext: "Return on every dollar spent on email marketing",
  },
];

export const autoSalesMultiSection = {
  featureBlocks: [
    {
      blockTitle: "Make payments & invoicing easy",
      blockSubTitle:
        "Customer preferences have changed, and more than ever they want the convenience of using credit cards and mobile payment methods like Apple Pay and Google pay, whether it’s for a tune up, a down payment, or even a new vehicle purchase. With SpotOn, we make it simple with payment options that are easy for you and your customers.",
      blockList: [
        "In-person payments",
        "Online payments & deposits",
        "Custom invoices & reminders",
        "Recurring payments",
        "Customer data capture",
        "Next-day funding & transparent pricing",
      ],
      blockImg: "auto-sales-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-sales/demo",
      },
    },
    {
      blockTitle: "Increase sales with online booking",
      blockSubTitle:
        "Boost efficiency and make it easier for your customers to know they’ll get the personalized attention they deserve when they show up for a test drive or service appointment. Studies show that sales can increase by as much as 40% by simply offering online booking opportunities to customers.",
      blockList: [
        "Appointment booking from your website and Facebook page",
        "Interactive calendar for drop-offs & appointments",
        "Employee shift management",
        "Mailing list builder",
      ],
      blockImg: "auto-sales-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-sales/demo",
      },
    },
    {
      blockTitle: "Attract new customers with a better website",
      blockSubTitle:
        "Attract more customers with a slick website for your automotive sales business. Our specialists will build it for you at a do-it-yourself price and optimize it so you show up higher in online searches and attract more customers.",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom website name",
        "Online appointment booking",
        "Search engine optimization",
      ],
      blockImg: "auto-sales-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-sales/demo",
      },
    },
    {
      blockTitle: "Create lifetime relationships with customers",
      blockSubTitle:
        "Don’t lose contact with past customers. We’ll set you up with multiple ways to easily collect contact information from every person who buys a vehicle, schedules a service appointment, or simply books a test drive. From there, it’s easy to stay connected with our integrated marketing tools.",
      blockList: [
        "Simple email campaign wizard to stay top-of-mind",
        "Facebook marketing campaigns to expand your reach",
        "Easy-to-read sales and customer metrics",
        "One intuitive dashboard you can access from your computer or mobile device",
      ],
      blockImg: "auto-sales-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-sales/demo",
      },
    },
    {
      blockTitle: "Manage your online reputation",
      blockSubTitle:
        "With SpotOn, you can easily let your customers know they've been heard. Your clients’ opinions on review sites like Yelp, Facebook, and Google—whether good or bad—can significantly impact your business. Proactively manage your online reputation to expand your online reach and create more selling opportunities.",
      blockList: [
        "Monitor and see all your online reviews in one place",
        "Get notified every time you get a new review",
        "Easily shift to review sites to respond",
        "Watch your reputation improves with time",
      ],
      blockImg: "auto-sales-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/auto-sales/demo",
      },
    },
  ],
};

export const autoSalesCTA = {
  title: "Book your consultation.",
  ctaImg: "auto-repair-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/auto-sales/demo",
  },
};

export const autoSalesVideo = {
  title: "The road to success",
  videoBtn: "Watch video",
  posterImg: "oil-thumb.png",
  posterWrapperClass: "bg-blue",
  videoUrlId: "4pIhYB_LvJM",
};

export const autoSalesTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const autoSalesArticles = {
  title: "Expert articles & advice on growing your auto sales business",
  bgText: "Articles.",
};

export const autoSalesFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const autoSalesCar = {
  title: "Run a different type of automotive business",
  subtext: "Auto sales lots are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "auto-repair.png",
      cardTitle: "Auto repair",
      cardContent: "Drive repeat visits and more",
      icon: "auto-repair.png",
      linkTo: "/automotive/auto-repair",
    },
    {
      bg: "auto-parts.png",
      cardTitle: "Auto parts",
      cardContent: "Tools to grow your business\n",
      icon: "auto-parts.png",
      linkTo: "/automotive/auto-parts",
    },
    {
      bg: "tire-center.png",
      cardTitle: "Tire center",
      cardContent: "Tech to drive efficiency and revenue",
      icon: "tire-shop.png",
      linkTo: "/automotive/tire-center",
    },
    {
      bg: "oil-change.png",
      cardTitle: "Oil Change Shop",
      cardContent:
        "Find more customers, book more appointments, keep them coming back",
      icon: "oil-change.png",
      linkTo: "/automotive/oil-change-shop",
    },
  ],
};
