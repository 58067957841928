import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  autoSalesHero,
  autoSalesSubHero,
  autoSalesWhiteGlove,
  autoSalesLargeFeatureA,
  autoSalesTrusted,
  autoSalesMultiSection,
  autoSalesCTA,
  autoSalesVideo,
  // autoSalesTestimonials,
  autoSalesArticles,
  autoSalesCar,
} from "../../../data/subverticals/automotive/auto-sales-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";
import {
  heroSuccess,
  NorthEastAutoSlides,
} from "../../../data/success-stories-data";
// import Articles from '../../components/Articles/Articles';
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";

import heroBg from "../../../images/hero-images/auto-parts-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);

const whiteGloveImport = "auto-repair-wg.png";

const AutoSales = () => {
  return (
    <Layout>
      <SEO
        title="Auto Parts Point of Sale (POS) Software | SpotOn"
        description="SpotOn's Auto Parts POS System is an all-in-one POS offering payment processing, appointment booking & marketing solutions. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/automotive/auto-parts/"
      />
      <BHero sectionData={autoSalesHero} heroBg="car-sales-hero.png" />
      <NoVisualsCustom sectionData={autoSalesSubHero} complex />
      <WhiteGlove
        sectionData={autoSalesWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={autoSalesLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={autoSalesTrusted} />
      <LargeFeatures
        sectionData={autoSalesMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={autoSalesCTA} />
      <SuccessStories sectionData={heroSuccess} slides={NorthEastAutoSlides} />
      <VideoSection sectionData={autoSalesVideo} />
      {/* <TestmonialReviews sectionData={autoSalesTestimonials} /> */}
      <Articles
        sectionData={autoSalesArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:auto",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={autoSalesCar} />
      {/* <Faqs sectionData={autoSalesFaqs} /> */}
    </Layout>
  );
};

export default AutoSales;
